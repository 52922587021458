 .sensorData .modebar {
    left: 40%;
}
 .sensorData .js-plotly-plot {
     margin: 10px;
     width: 100%;
     height: 710px;
 }

 .notFoundSensorData {
     height: 710px;
 }


