.trendChart .modebar {
    left: 50%;
}
 .trendChart .js-plotly-plot {
     margin: 10px;
     width: 100%;
     height: 420px;
 }

.notFoundTrend {
    height: 420px;
}


