 .vocData .modebar {
    left: 40%;
}
 .vocData .js-plotly-plot {
     margin: 10px;
     width: 100%;
     height: 420px;
 }

 .notFoundVocData {
     height: 420px;
 }


