.statistic .modebar {
    left: 55%;
}
 .statistic .js-plotly-plot {
     margin: 10px;
     width: 100%;
     height: 420px;
 }

 .notFoundStatisticData{
     height: 420px;
 }


