.infoButton {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 18px;
    height: 18px;
}

.infoButton, .box {
    background: white;
    padding: 16px;
    position: absolute;
    bottom: 32px;
    right: 8px;
    z-index: 1999;
    box-shadow: 0 3px 3px rgba(0,0,0,0.2);

    &.minimized {
        bottom: 92px;
    }
    &.length1 {
        bottom: calc(110px + 32px + 10px);
    }
    &.length2 {
        bottom: calc(164px + 32px + 10px);
    }
    &.length3orMore {
        bottom: calc(216px + 32px + 10px);
    }
}

.mainTitle {
    color: #696969;
    font-weight: bold;
    margin-bottom: 8px;
}