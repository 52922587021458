.noiseChart .modebar {
    left: 42%;
}
 .noiseChart .js-plotly-plot {
     margin: 10px;
     width: 100%;
     height: 420px;
 }

.notFoundNoiseData {
    height: 420px;
}



