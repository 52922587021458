 .compareStatisticData .modebar {
    left: 55%;
}
 .compareStatisticData .js-plotly-plot {
     margin: 10px;
     width: 100%;
     height: 510px;
 }

 .notFoundStatisticCompareData {
     height: 510px;
 }


