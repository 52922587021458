 .compareAmbientNoiseData .modebar {
    left: 47%;
}
 .compareAmbientNoiseData .js-plotly-plot {
     margin: 10px;
     width: 100%;
     height: 510px;
 }

 .notFoundAmbientNoise {
     height: 510px;
 }


