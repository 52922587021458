 .compareData .modebar {
    left: 47%;
}
 .compareData .js-plotly-plot {
     margin: 10px;
     width: 100%;
     height: 660px;
 }

 .notFoundCompareData {
     height: 660px;
 }


